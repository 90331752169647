import * as React from "react";

function SvgHangup(props) {
    return (
        <svg baseProfile="tiny" viewBox="0 0 512 512" overflow="visible" {...props}>
            <path
                fill={props.fill}
                d="M68.2 197.9C33 211.2 1 233.9 0 266.1c.4 17.8 1.7 35.6 3.9 53.3 2 17.2 17.5 29.6 34.8 27.6.4 0 .8-.1 1.2-.2l98.6-15.6c12.4-2 22.4-11.2 25.5-23.3l12.8-51c49.1-9.4 99.6-10.1 148.9-2l9.2 1.5 13 51.3c3.1 12.2 13.1 21.4 25.5 23.3l98.6 15.6c17.1 2.7 33.2-9.1 35.8-26.2.1-.4.1-.8.2-1.2 2.3-17.7 3.6-35.5 3.9-53.3-1-32.9-32.9-53.9-68.2-68.2-108.9-45-262.6-42.8-375.5.2z"
            />
        </svg>
    );
}

export default SvgHangup;